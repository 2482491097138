import type { ContentEvent } from "../app/content";

export const shouldShowEvent = (event: ContentEvent) => {
  const [year, month, day] = event.date.split("-");
  if (!year || !month || !day) {
    return false;
  }
  const endOfEvent = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    23,
    59,
    59,
    999,
  );
  return endOfEvent.getTime() - new Date().getTime() > 0;
};
