"use client";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  Box,
  ChakraProvider,
  Collapse,
  ColorModeScript,
  Flex,
  Heading,
  IconButton,
  Link,
  List,
  Text,
  extendTheme,
  useTheme,
} from "@chakra-ui/react";
import RouterLink from "next/link";
import { createRef, MouseEvent, ReactNode, useEffect, useState } from "react";
import { shouldShowEvent } from "../utils/shouldShowEvent";
import { FacebookIcon, InstagramIcon, MenuBurgerIcon } from "./icons";

import type { ContentEvent } from "../app/content";

const customTheme = {
  colors: {
    blue: {
      50: "#E7EDF4",
      100: "#B9CBDE",
      200: "#8BA8C8",
      300: "#5C86B3",
      400: "#2E639D",
      500: "#175292",
      600: "#004188",
      700: "#003670",
      800: "#003063",
      900: "#00244B",
    },
    red: {
      50: "#F9D0DC",
      100: "#F7B9CB",
      200: "#F4A2B9",
      300: "#F28BA8",
      400: "#ED5C86",
      500: "#E82E63",
      600: "#E30041",
      700: "#BA0036",
      800: "#91002A",
      900: "#68001E",
    },
  },
  initialColorMode: "light",
};

function StyledLink(props: {
  href: string;
  isSecondary?: boolean;
  children?: ReactNode;
}) {
  return (
    <Link
      as={RouterLink}
      href={props.href}
      display="block"
      px={props.isSecondary ? 8 : 4}
      py={4}
      _hover={{ bg: ["blue.600", "blue.600", "blue.500"] }}
      _focusWithin={{ bg: ["blue.600", "blue.600", "blue.500"] }}
    >
      {props.children}
    </Link>
  );
}

function Separator() {
  const theme = useTheme();
  return (
    <li
      role="separator"
      style={{
        borderTop: `${theme.borders["1px"]} ${theme.colors.white}`,
        margin: `0 ${theme.space[6]}`,
      }}
    />
  );
}

function Header(props: { events: ContentEvent[] }) {
  const [showDropdown, setShowDropdown] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const hideMenu = () => {
    setShowDropdown("");
    setIsVisible(false);
  };

  const header = createRef<HTMLDivElement>();
  useEffect(() => {
    const handleClick = (event: globalThis.MouseEvent) => {
      if (
        header.current &&
        event.target instanceof Node &&
        !header.current.contains(event.target)
      ) {
        setShowDropdown("");
        setIsVisible(false);
      }
    };
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [header, setShowDropdown]);

  const events = props.events.filter(shouldShowEvent);
  const eventsWithEnrolment = events.filter(
    (event) => event.hasEnrol && event.status === "open",
  );

  const menu = (
    <List
      bg={["blue.500", "blue.500", "blue.600"]}
      display={["block", "block", "flex"]}
    >
      <Box
        as="li"
        _hover={{ bg: ["blue.600", "blue.600", "blue.500"] }}
        onClick={hideMenu}
      >
        <StyledLink href="/">Startseite</StyledLink>
      </Box>
      <li>
        <Box
          as="a"
          href="#"
          display="block"
          _hover={{
            bg: ["blue.600", "blue.600", "blue.500"],
            cursor: "pointer",
          }}
          _focus={{
            bg: ["blue.600", "blue.600", "blue.500"],
          }}
          onClick={() => setShowDropdown(showDropdown === "Team" ? "" : "Team")}
          p={4}
        >
          Team
          {showDropdown === "Team" ? (
            <ChevronUpIcon height="20px" width="20px" ml={2} />
          ) : (
            <ChevronDownIcon height="20px" width="20px" ml={2} />
          )}
        </Box>
        <Box
          position={["initial", "initial", "absolute"]}
          bg={["blue.500", "blue.500", "blue.600"]}
          zIndex={1}
        >
          <Collapse in={showDropdown === "Team"} animateOpacity>
            <List onClick={hideMenu}>
              <Box as="li">
                <StyledLink isSecondary href="/team">
                  SkilehrerInnen
                </StyledLink>
              </Box>
              <Box as="li">
                <StyledLink isSecondary href="/abteilungsleitung">
                  Abteilungsleitung
                </StyledLink>
              </Box>
              <Separator />
              <Box
                as="li"
                _hover={{ bg: ["blue.600", "blue.600", "blue.500"] }}
                _focusWithin={{ bg: ["blue.600", "blue.600", "blue.500"] }}
              >
                <Link
                  href="http://www.tsv-eiselfing.de"
                  isExternal
                  display="block"
                  px={8}
                  py={4}
                >
                  Hauptverein
                  <ExternalLinkIcon ml={2} />
                </Link>
              </Box>
            </List>
          </Collapse>
        </Box>
      </li>
      <li>
        <Box
          as="a"
          href="#"
          display="block"
          _hover={{
            bg: ["blue.600", "blue.600", "blue.500"],
            cursor: "pointer",
          }}
          _focus={{
            bg: ["blue.600", "blue.600", "blue.500"],
          }}
          onClick={() => {
            setShowDropdown(showDropdown === "Termine" ? "" : "Termine");
          }}
          p={4}
        >
          Termine
          {showDropdown === "Termine" ? (
            <ChevronUpIcon height="20px" width="20px" ml={2} />
          ) : (
            <ChevronDownIcon height="20px" width="20px" ml={2} />
          )}
        </Box>
        <Box
          position={["initial", "initial", "absolute"]}
          bg={["blue.500", "blue.500", "blue.600"]}
          zIndex={1}
        >
          <Collapse in={showDropdown === "Termine"}>
            <List onClick={hideMenu}>
              {events.map((event) => (
                <Box as="li" key={event.id}>
                  <StyledLink isSecondary href={`/termine/${event.id}`}>
                    {event.title}
                  </StyledLink>
                </Box>
              ))}
              {events.length > 0 ? <Separator /> : null}
              <Box as="li">
                <StyledLink isSecondary href="/termine">
                  Alle Termine
                </StyledLink>
              </Box>
            </List>
          </Collapse>
        </Box>
      </li>
      <li>
        <Box
          as="a"
          href="#"
          display="block"
          _hover={{
            bg: ["blue.600", "blue.600", "blue.500"],
            cursor: "pointer",
          }}
          _focus={{
            bg: ["blue.600", "blue.600", "blue.500"],
          }}
          onClick={() => {
            setShowDropdown(showDropdown === "Anmeldung" ? "" : "Anmeldung");
          }}
          p={4}
        >
          Anmeldung
          {showDropdown === "Anmeldung" ? (
            <ChevronUpIcon height="20px" width="20px" ml={2} />
          ) : (
            <ChevronDownIcon height="20px" width="20px" ml={2} />
          )}
        </Box>
        <Box
          position={["initial", "initial", "absolute"]}
          bg={["blue.500", "blue.500", "blue.600"]}
          zIndex={1}
        >
          <Collapse in={showDropdown === "Anmeldung"}>
            <List onClick={hideMenu}>
              {eventsWithEnrolment.map((event) => (
                <Box as="li" key={event.id}>
                  <StyledLink isSecondary href={`/anmeldung/${event.id}`}>
                    {event.title}
                  </StyledLink>
                </Box>
              ))}
              {eventsWithEnrolment.length === 0 ? (
                <Box
                  as="li"
                  _hover={{ bg: ["blue.600", "blue.600", "blue.500"] }}
                  px={8}
                  py={4}
                  onClick={
                    ((event: MouseEvent<HTMLLIElement>) =>
                      event.stopPropagation()) as any
                  }
                >
                  Keine Termine vorhanden...
                </Box>
              ) : null}
            </List>
          </Collapse>
        </Box>
      </li>
      <Box as="li" _hover={{ bg: "blue.600" }} onClick={hideMenu}>
        <StyledLink href="/bilder">Bilder</StyledLink>
      </Box>
      <li>
        <Box
          as="a"
          href="#"
          display="block"
          _hover={{
            bg: ["blue.600", "blue.600", "blue.500"],
            cursor: "pointer",
          }}
          _focus={{
            bg: ["blue.600", "blue.600", "blue.500"],
          }}
          onClick={() => {
            setShowDropdown(showDropdown === "Kontakt" ? "" : "Kontakt");
          }}
          p={4}
        >
          Kontakt
          {showDropdown === "Kontakt" ? (
            <ChevronUpIcon height="20px" width="20px" ml={2} />
          ) : (
            <ChevronDownIcon height="20px" width="20px" ml={2} />
          )}
        </Box>
        <Box
          position={["initial", "initial", "absolute"]}
          bg={["blue.500", "blue.500", "blue.600"]}
          zIndex={1}
        >
          <Collapse in={showDropdown === "Kontakt"}>
            <List onClick={hideMenu}>
              <Box as="li">
                <StyledLink isSecondary href="/kontakt">
                  Kontaktformular
                </StyledLink>
              </Box>
              <Box as="li">
                <StyledLink isSecondary href="/kontakt/newsletter">
                  Newsletter
                </StyledLink>
              </Box>
            </List>
          </Collapse>
        </Box>
      </li>
    </List>
  );

  return (
    <header>
      <Box as="nav" bg="blue.600" color="blue.50" ref={header}>
        <Box
          display={["flex", "flex", "none"]}
          padding={2}
          justifyContent="flex-end"
        >
          <IconButton
            icon={<MenuBurgerIcon />}
            size="lg"
            aria-label="Menü öffnen"
            colorScheme="blue"
            onClick={() => setIsVisible((current) => !current)}
          />
        </Box>
        <Collapse in={isVisible}>{menu}</Collapse>
        <Box display={["none", "none", "block"]}>{menu}</Box>
      </Box>
    </header>
  );
}

function Footer() {
  return (
    <Box as="footer" bg="white">
      <Box mx={6} borderWidth="1px" />
      <Flex
        flexDirection={["column", "column", "row"]}
        justifyContent="space-around"
        alignItems="center"
        p={8}
      >
        <Flex flexDirection="column">
          <Heading as="h3" fontSize="md" textAlign="center">
            Weitere Links
          </Heading>
          <Flex
            mt={4}
            flexDirection={["row", "row", "column"]}
            justifyContent="center"
          >
            <Link
              href="https://www.facebook.com/SkiUndBoardEiselfing"
              color="blue.400"
              isExternal
              mx={4}
              display="flex"
              alignItems="center"
            >
              <FacebookIcon mr={2} />
              Facebook
            </Link>
            <Text as="span" display={["initial", "initial", "none"]}>
              &bull;
            </Text>
            <Link
              href="https://www.instagram.com/skiundboardeiselfing/"
              color="blue.400"
              isExternal
              mx={4}
              mt={[0, 0, 2]}
              display="flex"
              alignItems="center"
            >
              <InstagramIcon mr={2} />
              Instagram
            </Link>
            <Text as="span" display={["initial", "initial", "none"]}>
              &bull;
            </Text>
            <Link
              as={RouterLink}
              href="/info/impressum"
              color="blue.400"
              mx={4}
              mt={[0, 0, 2]}
            >
              Impressum
            </Link>
            <Text as="span" display={["initial", "initial", "none"]}>
              &bull;
            </Text>
            <Link
              as={RouterLink}
              href="/info/datenschutz"
              color="blue.400"
              mx={4}
              mt={[0, 0, 2]}
            >
              Datenschutz
            </Link>
          </Flex>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Text mt={[4, 4, 0]}>
            &copy; {new Date().getFullYear()} - Ski &amp; Board - TSV Eiselfing
          </Text>
          <Text mt={4}>
            Design und Umsetzung von{" "}
            <Link
              href="mailto:thomas.heyenbrock@gmail.com"
              color="blue.400"
              isExternal
            >
              Thomas Heyenbrock
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export function LayoutComponent(props: {
  children: ReactNode;
  events: ContentEvent[];
}) {
  return (
    <>
      <ColorModeScript initialColorMode="light" />
      <ChakraProvider theme={extendTheme(customTheme)}>
        <Flex flexDirection="column" minHeight="100vh">
          <Header events={props.events} />
          <Box as="main" flex="1 1 auto" bg="white" p={8}>
            {props.children}
          </Box>
          <Footer />
        </Flex>
      </ChakraProvider>
    </>
  );
}

export function Center(props: {
  children: ReactNode;
  maxWidth: "md" | "lg" | "xl";
}) {
  const theme = useTheme();
  const maxWidth = theme.breakpoints[props.maxWidth];
  return (
    <Box mx="auto" maxWidth={maxWidth}>
      {props.children}
    </Box>
  );
}
